import { p as aclImport } from './acl-cd02deb2.js';
import { a as argImport } from './arg-33a88d95.js';
import { a$ as asImport } from './as-318e037b.js';
import { a5 as calImport } from './cal-7a3ea416.js';
import { u as contactImport } from './contact-a1025d9f.js';
import { k as dcImport } from './dc-5f5380c9.js';
import { aw as dctImport } from './dct-ab257c3b.js';
import { a9 as foafImport } from './foaf-05174112.js';
import { h as httpImport } from './http-8179cb74.js';
import { a7 as hydraImport } from './hydra-a772aef4.js';
import { o as ldpImport } from './ldp-efa6e4d0.js';
import { l as linkImport } from './link-247081ec.js';
import { t as logImport } from './log-2f5c4638.js';
import { m as meetingImport } from './meeting-e6ab1dbe.js';
import { ag as owlImport } from './owl-4d58e757.js';
import { q as quImport } from './qu-52da41a2.js';
import { c as tripImport } from './trip-a4eac398.js';
import { e as rdfImport } from './rdf-2b18691f.js';
import { g as rdfsImport } from './rdfs-47a3d3a9.js';
import { s as schedImport } from './sched-1d1e63ee.js';
import { JZ as schemaImport } from './schema-73abb769.js';
import { JZ as schema_httpsImport } from './schema_https-29c7f9bc.js';
import { as as siocImport } from './sioc-49ca0ecf.js';
import { u as skosImport } from './skos-de6d709a.js';
import { y as solidImport } from './solid-5db7be47.js';
import { b as spaceImport } from './space-86738b33.js';
import { g as tabImport } from './tab-e36cfd85.js';
import { g as tabontImport } from './tabont-9bc9523e.js';
import { bf as vcardImport } from './vcard-9a881061.js';
import { Q as wfImport } from './wf-d698b4a6.js';

var acl = aclImport;
var arg = argImport;
var as = asImport;
var cal = calImport;
var contact = contactImport;
var dc = dcImport;
var dct = dctImport;
var foaf = foafImport;
var http = httpImport;
var hydra = hydraImport;
var ldp = ldpImport;
var link = linkImport;
var log = logImport;
var meeting = meetingImport;
var owl = owlImport;
var qu = quImport;
var trip = tripImport;
var rdf = rdfImport;
var rdfs = rdfsImport;
var sched = schedImport;
var schema = schemaImport;
var schema_https = schema_httpsImport;
var sioc = siocImport;
var skos = skosImport;
var solid = solidImport;
var space = spaceImport;
var tab = tabImport;
var tabont = tabontImport;
var vcard = vcardImport;
var wf = wfImport;

export { acl, arg, as, cal, contact, dc, dct, foaf, http, hydra, ldp, link, log, meeting, owl, qu, rdf, rdfs, sched, schema, schema_https, sioc, skos, solid, space, tab, tabont, trip, vcard, wf };
